#photos-gallery {
  margin-bottom: 30px;
  cursor: pointer;
  position: relative;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #00c6ff;
    content: '';
    opacity: 0;
  }
  &:hover {
    &::before {
      opacity: 0.4;
    }
  }

  ul {
    padding: 0;
    margin: 0;
  }
  ul li {
    list-style: none;
    display: inline-block;
    vertical-align: top;
    width: 30%;
    margin: 10px 0px 10px 20px;
  }
  ul li img {
    border-radius: 8px;
    max-width: 100%;
    height: auto;
  }
  @media (max-width: 770px) {
    ul li {
      width: 90%;
    }
  }
}
