.hayward-about {
  padding: 120px 0px;

  @media (max-width: 991px) {
    padding: 170px 0px 120px;
    background: #f5f5f5;
    margin-top: -85px;
  }

  .about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    position: relative;

    &::before {
      position: absolute;
      left: -295px;
      top: 0;
      width: 450px;
      height: 450px;
      z-index: -9;
      content: '';
      background: url(../img/circle.png) no-repeat center center / cover;
      animation: pulse5 1.5s infinite;
    }
    h2 {
      font-size: 28px;
      font-weight: 600;
      line-height: 38px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 30px;
    }
  }

  .about-img {
    position: relative;
    z-index: 9;
    margin-bottom: 57px;

    @media (max-width: 991px) {
      margin-top: 40px;
    }

    @media (max-width: 767px) {
      display: none;
    }

    &::before {
      position: absolute;
      right: -77px;
      top: 57px;
      width: 100%;
      height: 100%;
      background: #ffa63c;
      content: '';
      z-index: -9;
    }
    &::after {
      position: absolute;
      left: 5%;
      top: 5%;
      width: 90%;
      z-index: 9;
      height: 90%;
      border: 10px solid #fff;
      content: '';
    }
    img {
      width: 100%;
    }
  }

  .loadmore {
    font-size: 18px;
    line-height: 28px;
    padding-left: 75px;
    position: relative;
    font-weight: 500;
    font-style: italic;
    cursor: pointer;

    &:hover {
      color: #00c6ff;
    }
    &::before,
    &:after {
      position: absolute;
      left: 0;
      top: 50%;
      width: 60px;
      height: 3px;
      background: #00c6ff;
      content: '';
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
    }
    &:after {
      height: 15px;
      width: 15px;
      left: 45px;
      border-radius: 50%;
      box-shadow: 0 0 0 #00c6ff50;
      animation: pushLoading 1.5s infinite;
    }
  }
}

@-webkit-keyframes pulse5 {
  0% {
    transform: scale(1) rotate(90deg);
  }

  70% {
    transform: scale(1.01) rotate(180deg);
  }

  100% {
    transform: scale(1) rotate(360deg);
  }
}

@keyframes pulse5 {
  0% {
    transform: scale(1) rotate(90deg);
  }

  70% {
    transform: scale(1.05) rotate(180deg);
  }

  100% {
    transform: scale(1) rotate(360deg);
  }
}
