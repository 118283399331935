.header-area {
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;

  .sticky {
    background: #000000;
  }

  #hayward-header {
    img {
      display: block;
      padding: 14px 0;
      width: 170px;
    }

    .main-menu {
      .menu-item {
        margin-right: 25px;
        padding: 32px 0;
        font-size: 16px;
        cursor: pointer;

        a {
          position: relative;
          font-weight: 500;
          color: #fff;
        }

        a:before {
          background: #fff;
          position: absolute;
          left: 0;
          top: 10px;
          width: 0;
          height: 1px;
          content: '';
          transition: all 0.3s ease 0s;
        }

        a.active:before {
          width: 100%;
        }

        &:hover {
          a:before {
            width: 100%;
          }
        }
      }
    }

    .book-button {
      display: none;
      width: 100%;
      height: 45px;
      border: 2px solid #fff;
      border-radius: 30px;
      color: #fff;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 500;
      background: transparent;
      margin: 22px 0;
      white-space: nowrap;

      &:hover {
        background: #fff;
        color: #fca903;
      }

      @media (min-width: 900px) {
        display: block !important;
      }
    }

    .hamburger-menu {
      margin: 33px 0;
      float: right;
      list-style: none;
      cursor: pointer;

      li {
        height: 2px;
        width: 25px;
        background: #fff;
        margin-bottom: 3px;
        transition: all 0.6s ease-in-out 0s;
      }

      &.active {
        li:nth-child(1) {
          transform: translateY(5px) rotate(-135deg);
        }
        li:nth-child(2) {
          opacity: 0;
        }
        li:nth-child(3) {
          transform: translateY(-5px) rotate(135deg);
        }
      }

      @media (min-width: 1200px) {
        display: none !important;
      }
    }

    @media (max-width: 1200px) {
      .main-menu {
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        background: #5d5e5e;
        padding: 20px 40px;
        visibility: hidden;
        opacity: 0;
        transform: scaleY(0);
        -webkit-transform: scaleY(0);
        -moz-transform: scaleY(0);
        transform-origin: top;
        -webkit-transform-origin: top;
        z-index: 999;

        &.active {
          visibility: visible;
          opacity: 1;
          transform: scaleY(1);
          -webkit-transform: scaleY(1);
          -moz-transform: scaleY(1);
        }

        & > div {
          display: block;
          justify-content: flex-start;

          .menu-item {
            padding: 2px 0px;
            margin-right: 0;

            a {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

#newark {
  .header-area {
    position: sticky;
    background: #0e0e0e;
  }
}

#hayward {
  .header-area {
    position: absolute;
  }
}
